import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import './router.scss';

import {locales, routes} from "../../constants";
import {ComingSoon} from "modules";
import {Outline} from "components";
import {ETheme, TTheme} from "../../common";

export const Router = () => {
    // const
    const available = locales.getAvailableLanguages();
    const currentLanguage = locales.getLanguage();

    // useState
    const [nextLanguage, setNextLanguage] = useState<string>(available.find(e => e !== currentLanguage) as string);
    const [theme, setTheme] = useState<TTheme>(ETheme.default);

    // handlers
    const handleChangeLang = () => {
        locales.setLanguage(nextLanguage);
        setNextLanguage(available.find(e => e !== nextLanguage) as string);
    };

    const handleChangeTheme = () => {
        const localTheme = window.localStorage.getItem(ETheme.LOCALSTORAGE_NAME) ?? ETheme.default;
        if (localTheme === ETheme.default) {
            window.localStorage.setItem(ETheme.LOCALSTORAGE_NAME, ETheme.inverted);
            setTheme(ETheme.inverted);
            return;
        }
        window.localStorage.setItem(ETheme.LOCALSTORAGE_NAME, ETheme.default);
        setTheme(ETheme.default);
        return;
    }


    // useEffect
    useEffect(() => {
        const localTheme = window.localStorage.getItem(ETheme.LOCALSTORAGE_NAME) ?? ETheme.default;
        setTheme(localTheme as TTheme);
        if (localTheme === ETheme.inverted) return document.body.classList.add(ETheme.inverted);
        return document.body.classList.remove(ETheme.inverted)
    },[theme])

    const data = {
        lang: nextLanguage,
        theme,
    };

    const handlers = {
        handleChangeLang,
        handleChangeTheme,
    };

    return (
        <div className="full-screen">
            <Outline data={data} handlers={handlers}>
                <Routes>
                    <Route path={routes.main}>
                        <Route index element={<ComingSoon />} />
                    </Route>

                    <Route
                        path="*"
                        element={<Navigate replace to={routes.main} />}
                    />
                </Routes>
            </Outline>
        </div>
    );
};
