import React from 'react';

import {TOutline} from "./Outline.types";
import "./outline.scss";
import {locales} from "../../constants";

export const Outline: TOutline = ({
    children,
    data: {
        lang,
    },
    handlers: {
        handleChangeLang,
        handleChangeTheme
    },
}) => {
    return (
        <div className="outline">
            <div className="line line-tl line-v" />
            <div className="line line-tl line-h" />
            <div className="line line-br line-v" />
            <div className="line line-br line-h" />

            <div className="licence text-no-select">
                <span className="licence-copyright">{locales.general.copyright} </span>
                <span className="licence-year">{locales.general.year}</span>
            </div>

            <div className="lang-theme text-no-select">
                <span className="theme" onClick={handleChangeTheme} />
                <span className="lang" onClick={handleChangeLang}>{lang}</span>
            </div>

            <div className="outline-outlet">
                {children}
            </div>
        </div>
    );
};