import LocalizedStrings from 'react-localization';

export const locales = new LocalizedStrings({
    fr: {
        general: {
            soon: "prochainement ...",
            lang: "fr",
            copyright: "©",
            year: "/ 2023",
        }
    },
    en: {
        general: {
            soon: "coming soon ...",
            lang: "en",
            copyright: "©",
            year: "/ 2023",
        }
    }
});
