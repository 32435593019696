import React from 'react';
import { locales } from "../../constants";

import { TComingSoonUI } from "./ComingSoon.types";
import "./coming-soon.scss";

const ComingSoon: TComingSoonUI  = () => {
    return (
        <div className="coming">
            <div className="coming-text">
                {locales.general.soon}
            </div>
        </div>
    );
};

export default ComingSoon;
